"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMeasurementsFetcher = void 0;
const react_1 = require("react");
const fixedStreamSlice_1 = require("../../../store/fixedStreamSlice");
const hooks_1 = require("../../../store/hooks");
const useMeasurementsFetcher = (streamId) => {
    const isCurrentlyFetchingRef = (0, react_1.useRef)(false);
    const lastFetchedRangeRef = (0, react_1.useRef)({
        start: null,
        end: null,
    });
    const dispatch = (0, hooks_1.useAppDispatch)();
    const validateFetchRange = ({ start, end }) => {
        if (!streamId || isCurrentlyFetchingRef.current)
            return false;
        const now = Date.now();
        end = Math.min(end, now);
        return start < end;
    };
    const adjustFetchRange = ({ start, end }) => {
        const { start: lastStart, end: lastEnd } = lastFetchedRangeRef.current;
        if (lastStart === null || lastEnd === null)
            return { start, end };
        // Return null if data already exists
        if (start >= lastStart && end <= lastEnd)
            return null;
        // Adjust range to fetch only missing data
        if (start < lastStart)
            return { start, end: lastStart };
        if (end > lastEnd)
            return { start: lastEnd, end };
        return null;
    };
    const updateLastFetchedRange = (newRange) => {
        const { start, end } = lastFetchedRangeRef.current;
        lastFetchedRangeRef.current = {
            start: Math.min(newRange.start, start !== null && start !== void 0 ? start : newRange.start),
            end: Math.max(newRange.end, end !== null && end !== void 0 ? end : newRange.end),
        };
    };
    const fetchMeasurementsIfNeeded = (start, end) => __awaiter(void 0, void 0, void 0, function* () {
        const range = { start, end };
        if (!validateFetchRange(range))
            return;
        const adjustedRange = adjustFetchRange(range);
        if (!adjustedRange)
            return;
        isCurrentlyFetchingRef.current = true;
        try {
            yield dispatch((0, fixedStreamSlice_1.fetchMeasurements)({
                streamId: Number(streamId),
                startTime: Math.floor(adjustedRange.start).toString(),
                endTime: Math.floor(adjustedRange.end).toString(),
            })).unwrap();
            updateLastFetchedRange(adjustedRange);
        }
        catch (error) {
            console.error("Error fetching measurements:", error);
        }
        finally {
            isCurrentlyFetchingRef.current = false;
        }
    });
    return { fetchMeasurementsIfNeeded };
};
exports.useMeasurementsFetcher = useMeasurementsFetcher;
