"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileTimeRange = exports.FixedTimeRange = void 0;
var FixedTimeRange;
(function (FixedTimeRange) {
    FixedTimeRange["Hour"] = "hour";
    FixedTimeRange["Day"] = "day";
    FixedTimeRange["Week"] = "week";
    FixedTimeRange["Month"] = "month";
    FixedTimeRange["Custom"] = "custom";
})(FixedTimeRange || (exports.FixedTimeRange = FixedTimeRange = {}));
var MobileTimeRange;
(function (MobileTimeRange) {
    MobileTimeRange["FiveMinutes"] = "fiveMinutes";
    MobileTimeRange["Hour"] = "hour";
    MobileTimeRange["All"] = "all";
})(MobileTimeRange || (exports.MobileTimeRange = MobileTimeRange = {}));
