"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollEndListener = void 0;
const lodash_1 = require("lodash");
const react_1 = require("react");
const useScrollEndListener = (elementRef, onScrollEnd) => {
    (0, react_1.useEffect)(() => {
        const listInnerElement = elementRef.current;
        if (listInnerElement) {
            const saveScrollPosition = (0, lodash_1.debounce)((position) => {
                localStorage.setItem("sessionsListScrollPosition", position.toString());
            }, 150);
            const checkScrollEnd = (0, lodash_1.debounce)(() => {
                const { scrollTop, scrollHeight, clientHeight } = listInnerElement;
                const isNearBottom = scrollTop + clientHeight >= scrollHeight - 10;
                if (isNearBottom) {
                    onScrollEnd();
                }
            }, 200);
            const onScroll = () => {
                const { scrollTop } = listInnerElement;
                saveScrollPosition(scrollTop);
                checkScrollEnd();
            };
            const savedPosition = localStorage.getItem("sessionsListScrollPosition");
            if (savedPosition) {
                requestAnimationFrame(() => {
                    listInnerElement.scrollTop = parseInt(savedPosition);
                });
            }
            listInnerElement.addEventListener("scroll", onScroll, { passive: true });
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
                saveScrollPosition.cancel();
                checkScrollEnd.cancel();
            };
        }
    }, [elementRef, onScrollEnd]);
};
exports.useScrollEndListener = useScrollEndListener;
