"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.navItems = void 0;
const urls_1 = require("../../../const/urls");
exports.navItems = [
    {
        isNavTitle: true,
        href: urls_1.urls.airbeam,
        label: "navbar.sections.airbeam",
        subNav: [
            {
                href: urls_1.urls.userStories,
                label: "navbar.sections.userStories",
            },
            {
                href: urls_1.urls.airbeamHowItWorks,
                label: "navbar.sections.howItWorks",
            },
            {
                href: urls_1.urls.faq,
                label: "navbar.sections.faq",
            },
            {
                href: urls_1.urls.usersGuide,
                label: "navbar.sections.usersGuide",
            },
            {
                href: urls_1.urls.airbeamMiniUsersGuide,
                label: "navbar.sections.airbeamMiniUsersGuide",
            },
            {
                href: urls_1.urls.airbeamBuyNow,
                label: "navbar.sections.airbeamBuyNow",
            },
        ],
    },
    {
        isNavTitle: true,
        href: urls_1.urls.aircasting,
        label: "navbar.sections.aircasting",
        subNav: [
            {
                href: urls_1.urls.map,
                label: "navbar.sections.maps",
            },
            {
                href: urls_1.urls.android,
                label: "navbar.sections.androidApp",
            },
            {
                href: urls_1.urls.iOS,
                label: "navbar.sections.iOSApp",
            },
            {
                href: urls_1.urls.actions,
                label: "navbar.sections.actions",
            },
        ],
    },
    {
        isNavTitle: true,
        href: urls_1.urls.about,
        label: "navbar.sections.about",
        subNav: [
            {
                href: urls_1.urls.history,
                label: "navbar.sections.history",
            },
            {
                href: urls_1.urls.press,
                label: "navbar.sections.press",
            },
        ],
    },
    {
        isNavTitle: true,
        href: urls_1.urls.blog,
        label: "navbar.sections.blog",
    },
];
