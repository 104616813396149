"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMobileSeriesData = exports.createFixedSeriesData = exports.isValidMeasurement = void 0;
const isValidMeasurement = (m) => {
    return m.time !== undefined && m.value !== undefined;
};
exports.isValidMeasurement = isValidMeasurement;
const createFixedSeriesData = (measurements) => measurements === null || measurements === void 0 ? void 0 : measurements.filter(exports.isValidMeasurement).map(({ time, value }) => [time, value]).sort((a, b) => a[0] - b[0]);
exports.createFixedSeriesData = createFixedSeriesData;
const createMobileSeriesData = (data, isGraphData) => data
    .map((measurement) => ({
    x: measurement.time,
    y: isGraphData && "lastMeasurementValue" in measurement
        ? measurement.lastMeasurementValue
        : measurement.value,
    position: {
        lat: isGraphData && "point" in measurement
            ? measurement.point.lat
            : measurement.latitude,
        lng: isGraphData && "point" in measurement
            ? measurement.point.lng
            : measurement.longitude,
    },
}))
    .filter((point) => point.x !== undefined && point.y !== undefined)
    .sort((a, b) => a.x - b.x);
exports.createMobileSeriesData = createMobileSeriesData;
