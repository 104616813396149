"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIsDormantSessionsFetched = exports.selectIsActiveSessionsFetched = exports.selectFixedSessionsStatusFulfilled = exports.selectFixedSessionsPoints = exports.selectFixedSessionsList = exports.selectFixedSessionPointsBySessionId = exports.selectDormantFixedSessionsState = exports.selectActiveFixedSessionsState = void 0;
const reselect_1 = require("reselect");
const sessionFiltersSlice_1 = require("./sessionFiltersSlice");
const selectActiveFixedSessionsState = (state) => state.fixedSessions.activeSessions;
exports.selectActiveFixedSessionsState = selectActiveFixedSessionsState;
const selectDormantFixedSessionsState = (state) => state.fixedSessions.dormantSessions;
exports.selectDormantFixedSessionsState = selectDormantFixedSessionsState;
const selectFixedSessionsStatusFulfilled = (state) => state.fixedSessions.status === "FULFILLED" /* StatusEnum.Fulfilled */;
exports.selectFixedSessionsStatusFulfilled = selectFixedSessionsStatusFulfilled;
const selectFixedSessionsState = (state) => state.fixedSessions;
const selectIsActiveSessionsFetched = (0, reselect_1.createSelector)([selectFixedSessionsState], (fixedSessionsState) => fixedSessionsState.isActiveSessionsFetched);
exports.selectIsActiveSessionsFetched = selectIsActiveSessionsFetched;
const selectIsDormantSessionsFetched = (0, reselect_1.createSelector)([selectFixedSessionsState], (fixedSessionsState) => fixedSessionsState.isDormantSessionsFetched);
exports.selectIsDormantSessionsFetched = selectIsDormantSessionsFetched;
const transformSessionData = (sessions) => sessions.map(({ id, title, lastMeasurementValue, startTimeLocal, endTimeLocal, latitude, longitude, streams, }) => {
    const firstStream = streams[Object.keys(streams)[0]] || {};
    return {
        id,
        title,
        sensorName: (firstStream === null || firstStream === void 0 ? void 0 : firstStream.sensorName) || "",
        lastMeasurementValue,
        startTime: startTimeLocal,
        endTime: endTimeLocal,
        averageValue: firstStream.streamDailyAverage || 0,
        point: {
            lat: latitude,
            lng: longitude,
            streamId: firstStream.id.toString() || "0",
        },
        streamId: firstStream.id || 0,
    };
});
const selectSessionsByType = (0, reselect_1.createSelector)([
    selectActiveFixedSessionsState,
    selectDormantFixedSessionsState,
    (_, type) => type,
], (activeSessions, dormantSessions, type) => {
    return type === sessionFiltersSlice_1.FixedSessionsTypes.ACTIVE
        ? activeSessions
        : dormantSessions;
});
const selectTransformedSessionsByType = (0, reselect_1.createSelector)([selectSessionsByType], (sessions) => transformSessionData(sessions));
const selectFixedSessionsPoints = (0, reselect_1.createSelector)([selectTransformedSessionsByType], (transformedSessions) => {
    return transformedSessions.map(({ id, title, sensorName, lastMeasurementValue, startTime, endTime, point, }) => ({
        id,
        title,
        sensorName,
        startTime,
        endTime,
        lastMeasurementValue,
        point,
    }));
});
exports.selectFixedSessionsPoints = selectFixedSessionsPoints;
const selectFixedSessionsList = (0, reselect_1.createSelector)([selectTransformedSessionsByType], (transformedSessions) => {
    return transformedSessions.map(({ id, title, sensorName, averageValue, startTime, endTime, streamId, lastMeasurementValue, }) => ({
        id,
        title,
        sensorName,
        averageValue,
        startTime,
        endTime,
        streamId,
        lastMeasurementValue,
    }));
});
exports.selectFixedSessionsList = selectFixedSessionsList;
const selectFixedSessionPointsBySessionId = (0, reselect_1.createSelector)([
    selectSessionsByType,
    (_, __, sessionId) => sessionId,
], (sessions, sessionId) => {
    const transformedSessions = transformSessionData(sessions);
    const session = transformedSessions.find((session) => Number(session.id) === Number(sessionId));
    return session
        ? [
            {
                id: session.id,
                lastMeasurementValue: session.lastMeasurementValue,
                point: session.point,
            },
        ]
        : [];
});
exports.selectFixedSessionPointsBySessionId = selectFixedSessionPointsBySessionId;
