"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChartUpdater = void 0;
const react_1 = require("react");
const getTimeRange_1 = require("../../../utils/getTimeRange");
const useChartUpdater = ({ chartComponentRef, seriesData, isLoading, lastSelectedTimeRange, fixedSessionTypeSelected, }) => {
    const isFirstRender = (0, react_1.useRef)(true);
    const updateChartData = (0, react_1.useCallback)((chart, data) => {
        chart.series[0].setData(data, true, false, false);
    }, []);
    const applySelectedRange = (0, react_1.useCallback)((chart) => {
        if (!lastSelectedTimeRange || !chart.rangeSelector)
            return;
        const selectedIndex = (0, getTimeRange_1.getSelectedRangeIndex)(lastSelectedTimeRange, fixedSessionTypeSelected);
        chart.rangeSelector.clickButton(selectedIndex, true);
    }, [lastSelectedTimeRange, fixedSessionTypeSelected]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (!seriesData || isLoading || !((_a = chartComponentRef.current) === null || _a === void 0 ? void 0 : _a.chart))
            return;
        const chart = chartComponentRef.current.chart;
        if (isFirstRender.current) {
            updateChartData(chart, seriesData);
            isFirstRender.current = false;
        }
        applySelectedRange(chart);
    }, [seriesData, isLoading, updateChartData, applySelectedRange]);
    return {
        updateChartData,
        applySelectedRange,
    };
};
exports.useChartUpdater = useChartUpdater;
