"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapIndexToTimeRange = exports.getSelectedRangeIndex = void 0;
const timeRange_1 = require("../types/timeRange");
const getSelectedRangeIndex = (timeRange, fixedSessionTypeSelected) => {
    if (fixedSessionTypeSelected) {
        switch (timeRange) {
            case timeRange_1.FixedTimeRange.Day:
                return 0;
            case timeRange_1.FixedTimeRange.Week:
                return 1;
            case timeRange_1.FixedTimeRange.Month:
                return 2;
            case timeRange_1.FixedTimeRange.Custom:
                return 3;
            default:
                return 0;
        }
    }
    else {
        switch (timeRange) {
            case timeRange_1.MobileTimeRange.FiveMinutes:
                return 0;
            case timeRange_1.MobileTimeRange.Hour:
                return 1;
            case timeRange_1.MobileTimeRange.All:
                return 2;
            default:
                return 2;
        }
    }
};
exports.getSelectedRangeIndex = getSelectedRangeIndex;
const mapIndexToTimeRange = (index, fixedSessionTypeSelected) => {
    if (fixedSessionTypeSelected) {
        switch (index) {
            case 0:
                return timeRange_1.FixedTimeRange.Day;
            case 1:
                return timeRange_1.FixedTimeRange.Week;
            case 2:
                return timeRange_1.FixedTimeRange.Month;
            case 3:
                return timeRange_1.FixedTimeRange.Custom;
            default:
                return timeRange_1.FixedTimeRange.Day;
        }
    }
    else {
        switch (index) {
            case 0:
                return timeRange_1.MobileTimeRange.FiveMinutes;
            case 1:
                return timeRange_1.MobileTimeRange.Hour;
            case 2:
                return timeRange_1.MobileTimeRange.All;
            default:
                return timeRange_1.MobileTimeRange.All;
        }
    }
};
exports.mapIndexToTimeRange = mapIndexToTimeRange;
